import { createAsyncThunk } from '@reduxjs/toolkit';
import { addToOrder } from './orderSlice';
import { generatePostCall } from '../api_calls/generatePostCall';
import { API_ENDPOINT_NAME } from '../utils/constants';


export const AddOrderToServer = createAsyncThunk(
  'order/addOrderToServer',
  async (orderData, { dispatch }) => {
    try {
      const response = await generatePostCall({ apiPath: API_ENDPOINT_NAME.Add_ORDER, data: orderData });
      if (response.success) {
        dispatch(addToOrder(orderData));
      }

      return response.data;
    } catch (error) {
      throw Error(error);
    }
  }
);
