import React from 'react';
import styles from '../../../styles/orderAnimation/orderAnimationOne.module.scss';
const OrderAnimationOne = () => {
  return (<>
    <div className={styles.order_animation}>
      <div className={styles.loader}></div>
    </div>
  </>);
};


export default OrderAnimationOne;
