import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AddOrderToServer } from './orderThunks.js'; // Update the import path as needed

const initialState = {
  order: [],
  instructions: null,
  totalPrice: 0,
  loading: false,
  placedOrder: [],
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    addToOrder: (state, action) => {
      const { _id, ...rest } = action.payload;
      const existingItemIndex = state.order.findIndex(item => item._id === _id);

      if (existingItemIndex !== -1) {
        // If item already exists, increase quantity
        state.order[existingItemIndex].quantity += 1;
      } else {
        // If item doesn't exist, add it to the order array
        state.order.push({ _id, ...rest });
      }
    },

    editCustomizedItem: (state, action) => {
      return {
        ...state,
        order: action.payload
      };
    },

    addInstuctionToOrder: (state, action) => {
      return {
        ...state,
        instructions: action.payload,
      };
    },
    totalPriceOfOrder: (state, action) => {
      return {
        ...state,
        totalPrice: action.payload
      };
    },
    addToPlacedOrder: (state, action) => {
      return {
        ...state,
        placedOrder: [...state.placedOrder, action.payload],
      };
    },
    resetOrder: (state, action)=>{
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddOrderToServer.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddOrderToServer.fulfilled, (state, action) => {
        state.loading = false;
        // No need to handle order update here since it's done within the thunk if API succeeds
      })
      .addCase(AddOrderToServer.rejected, (state) => {
        state.loading = false;
        // Optionally handle error state or messages here
      });
  },
});


const selectOrderSlice = (state) => state.order;

// Define a selector function to get the orders from the order slice
export const selectOrders = createSelector([selectOrderSlice], (orderSlice) => orderSlice.order);

export const getOrderInstructions = createSelector([selectOrderSlice], orderSlice => orderSlice.instructions);
export const getCompleteOrder = createSelector(
  [selectOrderSlice],
  orderSlice => ({
    order: orderSlice.order,
    instructions: orderSlice.instructions
  })
);

export const getPlaceOrder = createSelector(
  [selectOrderSlice],
  orderSlice => orderSlice.placedOrder
);
export const { addToOrder, editCustomizedItem, addInstuctionToOrder, totalPriceOfOrder, addToPlacedOrder, resetOrder } = orderSlice.actions;


export default orderSlice.reducer;
