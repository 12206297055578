// userSlice.js
import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  role: 'EASEBITE_SUPER_ADMIN',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state, action) {
      console.log(action.payload);
      state.isAuthenticated = true;
      state.role = action.payload;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.role = null;
    },
  },
});

const userRole = (state) => state.user.role;

export const getUserRole = createSelector(
  [userRole],
  role => role,
);

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
