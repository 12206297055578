const API_ENDPOINT_NAME = {
  // AUTH
  SIGNUP: '/auth/register',
  LOGIN: '/auth/login',
  OTPVERIFICATION: '/auth/verifyOTP',
  COUNTRY_PHONE_CODE:
    'https://gist.githubusercontent.com/DmytroLisitsyn/1c31186e5b66f1d6c52da6b5c70b12ad/raw/2bc71083a77106afec2ec37cf49d05ee54be1a22/country_dial_info.json',
  RESEND: '/auth/resendOtp',

  // ADMIN FOOD
  ADD_ITEM: '/admin/addItem',
  DELETE_ITEM: '/admin/deleteItem',
  EDIT_ITEM: '/admin/editItem',
  UPLOAD_MENU: '/admin/restaurant/uploadmenu',

  //ORDER
  PLACE_ORDER: '/user/order',
  GET_ORDER: '/user/order',

  //CATEGORY
  GET_CATEGORY: '/user/category',
  GET_ADMIN_CATEGORY: '/admin/category',
  TEST: '/admin/deleteCategory',
  EDIT_CATEGORY: '/admin/editCategory',

  //SEARCH
  GET_SEARCH: '/user/search',

  //DISCOUNT
  GET_DISCOUNT: '/admin/offer',

  //RESERVATIONS
  GET_RESERVATIONS: '/user/reservations',
  ADD_RESERVATIONS: '/user/reservations',
  GET_PARTICULAR_RESERVATIONS: '/admin/reservation',

  // TABLES
  ADD_TABLE: '/admin/table',
  GET_TABLE: '/admin/table',
  GET_TABLE_LIST: '/user/tableList',


  // USER
  CREATE_CUSTOMER: '/user/createUser',
  GET_USER_ROLE: '/user/userRole',

  //CHECK_ADMIN
  CHECK_ADMIN: '/auth/authAdmin',

  //HISTORY
  HISTORY_RESERVATIONS: '/user/reservationsHistory',

  ENABLE_OR_DISABLE_DISH: '/admin/dishAvailability',
};

const USER_ROLES = {
  EASEBITE_SUPER_ADMIN: 'EASEBITE_SUPER_ADMIN',
  RESTAURANT_ADMIN: 'RESTAURANT_ADMIN',
  RESTAURANT_EMPLOYEE: 'RESTAURANT_EMPLOYEE',
  EASEBITE_USER: 'EASEBITE_USER',
};

export { API_ENDPOINT_NAME, USER_ROLES };
