import Axios from 'axios';

const generatePostCall = async ({
  apiPath,
  data,
  urlId = null,
  isFile = false,
}) => {
  let header = {
    Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    'content-type': 'application/json',
  };

  if (isFile) {
    let tempData = data;
    data = new FormData();
    if (isFile === true) {
      let filesObj;
      if (tempData.categoryFile && tempData.categoryFile.length > 0) {
        filesObj = tempData.categoryFile;
        for (let i = 0; i < filesObj.length; i++) {
          data.append('file', filesObj[i]);
        }
        delete tempData.categoryFile;
      }
      filesObj = tempData.file;
      console.log(filesObj);
      if (filesObj) {
        for (let i = 0; i < filesObj.length; i++) {
          data.append('file', filesObj[i]);
        }
      }
      delete tempData.file;
    }
    Object.keys(tempData).forEach((key) => {
      if (Array.isArray(tempData[key]) || (typeof value === 'object' && tempData[key] !== null)) {
        data.append(key, JSON.stringify(tempData[key]));
      } else {
        data.append(key, tempData[key]);
      }
    });

    // adding content type to header for file
    header['Content-Type'] = 'multipart/form-data';
  }


  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'http://localhost:5001/api/v1' + apiPath + (urlId ? '/' + urlId : ''),
    headers: header,
    data: data,
  };

  try {
    const response = await Axios.request(config);

    if (response.status === 200 || response.status === 201) {
      return {
        success: true,
        message: response.data.message,
        response: response,
      };
    } else if (response.status === 701) {
      // localStorage.removeItem('accessToken');
      // localStorage.removeItem('userType');
    } else {
      return {
        success: false,
        message: response.data.message,
        response: response,
      };
    }
  } catch (error) {
    console.log('Error  ', error);

    // Check if the error has a response with a status code
    if (error.response && error.response.status === 701) {
      // localStorage.removeItem('accessToken');
      // localStorage.removeItem('userType');
    }

    return {
      success: false,
      message: error.response?.data?.message || 'Internal server error',
      key: error.response?.data?.key || '',
    };
  }
};

export { generatePostCall };
