import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import styles from './App.module.scss';
import OrderAnimationOne from './views/orderPage/orderAnimation/OrderAnimationOne.js';
import Receipt from './views/orderList/Receipt.js';


const HomePage = lazy(() => import('./views/home/HomePage.js'));
const TakeAwayIndex = lazy(() => import('./views/takeAway/TakeAwayIndex.js'));
const CartIndex = lazy(() => import('./components/cart/CartIndex.js'));
const OrdersIndex = lazy(() => import('./views/orderPage/OrdersIndex.js'));
const DineInIndex = lazy(() => import('./views/dineIn/DineInIndex.js'));
const Login = lazy(() => import('./views/login/Login.js'));
const Signup = lazy(() => import('./views/signup/Signup.js'));
const AdminIndex = lazy(() => import('./views/adminFood/AdminIndex.js'));
const AdminTables = lazy(() => import('./views/adminTables/AdminTables.js'));
const ChefIndex = lazy(() => import('./views/chefScreen/ChefIndex.js'));
const WaiterIndex = lazy(() => import('./views/waiterScreen/WaiterIndex.js'));
const ReservationsIndex = lazy(() => import('./views/reservations/ReservationsIndex.js'));
const Employee = lazy(() => import('./views/employee/Employee.js'));
const OrderList = lazy(() => import('./views/orderList/orderListIndex.js'));


const AnimationWrapper = () => {

  const styles = {
    wrapper: {
      height: '100vh',
      width: '100vh',
      display: 'grid',
      placeItems: 'center',
    }
  };
  return <div style={styles.wrapper}>
    <OrderAnimationOne key={1} />
  </div>;
};

const App = () => {
  return (
    <div className={styles.App}>
      <div className={styles.app_wrapper}>
        <BrowserRouter>
          <Routes>
            {/* Onboarding Routes */}
            <Route path="/login" element={<Suspense fallback={<AnimationWrapper />}><Login /></Suspense>} />
            <Route path="/signup" element={<Suspense fallback={<AnimationWrapper />}><Signup /></Suspense>}/>

            {/* Admin Routes  */}
            <Route path="/admin/menu" element={<Suspense fallback={<AnimationWrapper />}><AdminIndex /></Suspense>} />
            <Route path="/admin/cart" element={<Suspense fallback={<AnimationWrapper />}><CartIndex /></Suspense>} />
            <Route path="/admin/tables" element={<Suspense fallback={<AnimationWrapper />}><AdminTables /></Suspense>} />

            {/* User Routes */}
            <Route path="/:restaurantID" element={<Suspense fallback={<AnimationWrapper />}><HomePage /></Suspense>} />
            <Route path="/:restaurantID/takeaway" element={<Suspense fallback={<AnimationWrapper />}><TakeAwayIndex /></Suspense>} />
            <Route path="/:restaurantID/takeaway/cart" element={<Suspense fallback={<AnimationWrapper />}><CartIndex /></Suspense>} />
            <Route path="/:restaurantID/takeaway/orders" element={<Suspense fallback={<AnimationWrapper />}><OrdersIndex /></Suspense>} />
            <Route path="/:restaurantID/dineIn/:tableNumber" element={<Suspense fallback={<AnimationWrapper />}><DineInIndex /></Suspense>} />
            <Route path="/:restaurantID/dineIn/:tableNumber/cart" element={<Suspense fallback={<AnimationWrapper />}><CartIndex /></Suspense>} />
            <Route path="/:restaurantID/dineIn/:tableNumber/orders" element={<Suspense fallback={<AnimationWrapper />}><OrdersIndex /></Suspense>} />

            <Route path="/chef" element={<Suspense fallback={<AnimationWrapper />}><ChefIndex /></Suspense>} />
            <Route path="/waiter" element={<Suspense fallback={<AnimationWrapper />}><WaiterIndex /></Suspense>} />
            <Route path="/employee" element={<Suspense fallback={<AnimationWrapper />}><Employee /></Suspense>} />
            <Route path="/orderList" element={<Suspense fallback={<AnimationWrapper />}><OrderList /></Suspense>} />
            <Route path="/receipt" element={<Suspense fallback={<AnimationWrapper />}><Receipt /></Suspense>} />
            <Route path="/:restaurantID/reservations" element={<Suspense fallback={<AnimationWrapper />}><ReservationsIndex /></Suspense>} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
};

export default App;
