import React from 'react';
import styles from '../../styles/orderList/receipt.module.scss';
import path from '../../assets/components/common/Path.svg';


const order = {
  orderId: 'OD145',
  type: 'DineIn - Table 04',
  name: 'Robert kipp',
  items: [
    { name: 'Chicken Biryani',
      price: 660,
      customised: ['cheese1', 'cheese1'],
      isVeg: false,
      quatity: 1,
    },
    { name: 'Chicken',
      price: 260,
      customised: ['cheese1', 'cheese1'],
      isVeg: false,
      quatity: 2,
    }
  ],
  date: '12/02/2023',
  time: '10:29am',
  instructions: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.',
  price: 2620
};


const Receipt = ({ }) => {
  return (<>
    <div className={styles.receipt_wrapper}>
      <header>
        <span>Namaskar Restro</span>
        <span> Sector76, Noida</span>
        <span className={styles.date_wrapper_particularOrder}>
          <span>Wed,</span> <span>May 27, 2020 </span>
          <span>9:27:53 AM</span></span>
      </header>
      <div className={styles.path_wrapper}>
        <img src={path} alt='path' />
        <span>OD1254</span>
        <span className={styles.orderNo}>
            Order No
        </span>
      </div>
      <div className={styles.particularOrder_heading_wrapper}>
        <span>Item</span>
        <span>Qty</span>
        <span>Amount</span>
      </div>
      {order.items.map((item, index) => {
        return <><div className={styles.particularOrder_heading_wrapper} key={index}>
          <span>{item.name}</span>
          <span>{item.quatity}</span>
          <span>{item.price}</span>
        </div>
        <span className={styles.dotted__seperator}></span></>;
      })}
      <div className={styles.additional_details}>
        <span>Amount</span>
        <span>950.00</span>
        <span>Tax 5%</span>
        <span>50.00</span>
        <span>Amount</span>
        <span>{1000.00}</span>
      </div>
      <span className={styles.dotted__seperator}></span>
      <div className={styles.additional_details}>
        <span>Operator</span>
        <span>Rajesh</span>
      </div>
      <div className={styles.endingLine}>Thank You for visiting us, Do visit again</div>
    </div>
  </>);
};

export default Receipt;
