// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import categoryReducer from './categorySlice';
import orderReducer from './orderSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    category: categoryReducer,
    order: orderReducer
  },
});
